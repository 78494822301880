<template>
  <div id="mentionsLegales">

      <bloc-header2 page="Mentions légales" />

    <div id="container-mentionsLegales" class="containerAffichage">

        <div class="mentionsLegales">
            <h3>1 Editeur</h3>
<p>
Le portail {{lUrl}} est un site web public permettant l’accès à la plateforme privée {{lAppli}}.
<span class="br"></span>
Il est édité par la Région Grand Est.
</p>
<ul><li>
1, Place Adrien Zeller – 67070 STRASBOURG Cedex</li><li>
+33 (0)3 88 15 68 67</li><li>
Directeur de la publication : Président du Conseil Régional Grand Est</li><li>
Contact : legal[at]orientest.fr
</li></ul>
<h3>2 Hébergement</h3>
<p>
Addeo,
</p>
<ul><li>406 Boulevard Jean Jacques Bosc 33130 BEGLES</li><li>
+33 (0)5 56 48 46 60</li><li>
SAS au capital de 170 000 € - RCS 349 901 553</li><li>
Contact : tech[at]privilis.com
</li></ul>

<h3>3 Contenus</h3>
<p>
Les données mises en ligne sur le site internet public {{lUrl}} n'ont pas d'autre visée que d'ouvrir au plus large public l'information sur les enjeux, le fonctionnement, l’actualité et les retours d’expérience de l’initiative Orient'Est.
<span class="br"></span>
La Région Grand Est se réserve le droit exclusif de modifier, restreindre ou supprimer le site ou une partie de son contenu, à sa seule discrétion et sous quelque forme que ce soit.
<span class="br"></span>
L’exactitude des informations est périodiquement contrôlée et leur mise à jour est en tant que de besoin assurée par les services de la Région Grand Est. Des erreurs ou omissions indépendantes de la volonté de la Région Grand Est sont toutefois susceptibles de se glisser fortuitement dans les pages de son site.
<span class="br"></span>
A cet égard, la Région Grand Est ne répond pas de ces erreurs ou omissions éventuelles.
<span class="br"></span>
En tout état de cause, la responsabilité de la Région Grand Est ne saurait être retenue en cas de préjudice direct ou indirect (notamment tout préjudice financier, commercial, perte de programmes ou de données) résultant de l'usage de ce site et de l'utilisation des données et informations qui y sont mises en ligne.
<span class="br"></span>
La Région Grand Est décline toute responsabilité à l'égard de tout dommage lié à l'utilisation des liens hypertextes mis en ligne sur son site et à la consultation des sites auxquels ils renvoient. Il est expressément rappelé que la Région Grand Est n'a aucun contrôle ni aucune responsabilité sur le contenu de ces sites. Il incombe donc à chaque utilisateur de prendre les précautions nécessaires afin de s'assurer que le site sélectionné pour son usage n'est pas infesté de virus, cheval de Troie ou autre parasite de nature destructive ou qu’il pourrait lui causer un préjudice de toute autre nature (moral, etc.)
<span class="br"></span>
La Région Grand Est autorise la mise en place d’un lien hypertexte renvoyant vers son site Internet grandest.fr. Les informations utilisées ne doivent l'être qu'à des fins personnelles, associatives ou professionnelles.
<span class="br"></span>
De façon générale, toute utilisation totale ou partielle du site à des fins commerciales ou publicitaires est strictement exclue.
</p>

<h3>4 Confidentialité et protection des données</h3>
<p>
Un cookie est un fichier de petite taille qui ne permet pas l'identification de l'utilisateur. En naviguant sur le site {{lUrl}}, vous acceptez l'utilisation de cookies. Le dépôt de ces informations nous permet de réaliser des statistiques de visite de ce service.
<span class="br"></span>
Pour davantage d’informations sur vos droits, consultez : <a target="_blank" title="Région Grand Est - Données personnelles" href="https://www.grandest.fr/donnees-personnelles">https://www.grandest.fr/donnees-personnelles</a>
</p>

<h3>5 Respect des droits d'auteurs</h3>
<p>
L'ensemble de ce site relève de la législation française relative à la protection du droit d'auteur et à la propriété intellectuelle.
<span class="br"></span>
La structure générale, ainsi que les logiciels, textes, images animées ou non, son savoir-faire, et tous autres éléments composants le site sont la propriété exclusive de la Région Grand Est ou de ses sous-traitants.
<span class="br"></span>
Par conséquent, toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l'autorisation expresse de la Région Grand Est est interdite et constituerait une contrefaçon sanctionnée par le Code de la Propriété Intellectuelle.
<span class="br"></span>
Toute reproduction totale ou partielle de ses logos ayant fait l'objet d'un dépôt auprès de l'INPI, effectuée à partir des éléments du site sans l'autorisation expresse de la Région Grand Est est donc prohibée et est passible de sanctions.
<span class="br"></span>
Pour obtenir l'autorisation de reproduire des éléments du site, une demande doit être adressée à la Direction de la Communication de la Région Grand Est
</p>
<ul><li>1 place Adrien Zeller – BP 91006</li><li>67070 Strasbourg Cedex</li></ul> ou par courriel à :<ul><li>contact[at]grandest.fr.</li></ul>
<p>
    Lorsque la reproduction a été expressément autorisée, elle sera en tout état de cause soumise à l'obligation de faire apparaître la mention claire et lisible de l'adresse internet du site d'origine&nbsp;: <span class="mevMoyen">https://{{lUrl}}</span>
</p>

<h3>6 Crédits et références techniques</h3>
<p>
Portail public : Conception, création et réalisation graphique, Intégration, Maintenance : Addeo
</p>
<p>
Photos : Région Grand Est, Addeo, Shutterstock : Zephyr_p, Monkey Business Images, Fizkes, Wavebreakmedia
</p>
<p>
Plateforme : La plateforme {{lAppli}} est basée sur une personnalisation de la solution <a target="_blank" title="Wataycan, mon ePortfolio de compétences tout au long de la vie" href="https://www.wataycan.com/">Wataycan</a> éditée par la société : Addeo SAS - Directeur : M. Jacques Peyrondet
</p>
<p>
Marques et modèles : La marque {{laMarque}} est une propriété de la Région Grand Est et une marque déposée à l’INPI sous le numéro {{lInpi}}.
<span class="br"></span>
Le logo {{laMarque}} est une propriété de la Région Grand Est.
</p>


        </div>
    </div>

    <bloc-footer/>
  </div>
</template>


<script>
import Header2 from '@/components/Header2.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'MentionsLegales',
    data() {
        return {
            tmp:true,
        }
    },
    components: {
        'bloc-header2': Header2,
        'bloc-footer': Footer,
    },
    props: {
        sitePro: {
            type: Boolean
        },
    },
    created() {
        if (this.sitePro) {
            this.$parent.sitePRO = true;
        }
    },
    computed: {
        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        sitePRO() {
            return(this.$parent.sitePRO);
        },
        lUrl() {
            if (this.markOrientest) {
                if (!this.sitePRO) {
                    return("monorientest.fr");
                }
                else {
                    return("orientest.pro");
                }
            }
            else {
                if (!this.sitePRO) {
                    return("www.lorfolio.fr");
                }
                else {
                    return("www.lorfolio.pro");
                }
            }
        },
        lAppli() {
            if (this.markOrientest) {
                return("mon.orientest.fr");
            }
            else {
                return("Lorfolio Grand Est");
            }
        },
        laMarque() {
            if (this.markOrientest) {
                return("Orient'Est");
            }
            else {
                return("Lorfolio");
            }
        },
        lInpi() {
            if (this.markOrientest) {
                return("4669615");
            }
            else {
                return("4669610");
            }
        }
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #mentionsLegales {
        text-align: center;

        #container-mentionsLegales {
            text-align: left;
            width:90%;
            max-width: 760px;
            margin:0 auto;
            padding:2em 0;

			/*padding-top:50px;*/

            h3 {
                margin:1em 0;
            }
            p {
                margin:0.6em 0;
                line-height: 1.4em;
            }
            span.br {
                display:block;
                height:0.4em;
            }
            ul {
                padding:0.2em 0 0.4em 3em ;
                ::marker {
                    color:#aaa;
                }
                li {
                    margin-bottom:0.4em;
                    line-height: 1.4em;
                }
            }

            .mentionsLegales {
            }
        }
    }
</style>
